import { generateStage } from './utils/url';
import { generateCompanyAPI } from './apps/creditcheck/utils/url';

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_RELEASE_STAGE: string;
      REACT_APP_COGNITO_APP_CLIENT_ID: string;
      REACT_APP_COGNITO_COOKIE_DOMAIN: string;
      REACT_APP_COGNITO_COOKIE_SECURE?: '0' | '1';
      REACT_APP_COGNITO_IDENTITY_POOL_ID: string;
      REACT_APP_COGNITO_REGION: string;
      REACT_APP_COGNITO_USER_POOL_ID: string;
      REACT_APP_LOGIN_URL: string;
      REACT_APP_MHUB_API_URL: string;
    }
  }
}

export const mhubConfig = {
  api: process.env.REACT_APP_MHUB_API_URL,
};

export const setupAmplifyConfig = () => {
  const {
    REACT_APP_RELEASE_STAGE: releaseStage,
    REACT_APP_COGNITO_APP_CLIENT_ID: appClientId,
    REACT_APP_COGNITO_COOKIE_DOMAIN: cookieDomain,
    REACT_APP_COGNITO_IDENTITY_POOL_ID: identityPoolId,
    REACT_APP_COGNITO_REGION: region,
    REACT_APP_COGNITO_USER_POOL_ID: userPoolId,
    REACT_APP_COGNITO_COOKIE_SECURE: cookieSecure = '0',
  } = process.env;
  return {
    Auth: {
      cookieStorage: {
        domain: cookieDomain,
        secure: cookieSecure,
      },
      identityPoolId,
      mandatorySignIn: true,
      region,
      userPoolId,
      userPoolWebClientId: appClientId,
    },
    API: {
      endpoints: [
        {
          name: 'lawfirmAPI',
          endpoint: `https://lawfirms-api${generateStage(releaseStage)}.mhub.my`,
          region: 'ap-southeast-1',
          custom_header: async () => ({
            'Content-Type': 'application/json',
            'X-Api-Token': process.env.REACT_APP_LAWFIRM_API_TOKEN,
          }),
        },
        {
          name: 'reportAPI',
          endpoint: `https://reports-export-service${generateStage(releaseStage)}.mhub.my`,
          region: 'ap-southeast-1',
        },
        {
          name: 'lawyerAPI',
          endpoint: `https://lawyer-service.${releaseStage}.mhub.my`,
          region: 'ap-southeast-1',
          custom_header: async () => ({
            'Content-Type': 'application/json',
            'X-Api-Token': process.env.REACT_APP_LAWYER_API_TOKEN,
          }),
        },
        {
          name: 'creditCheckAPI',
          endpoint: `https://creditcheck-api${generateStage(releaseStage)}.mhub.my`,
          region: 'ap-southeast-1',
        },
        {
          name: 'companyAPI',
          endpoint: generateCompanyAPI(releaseStage),
          region: 'ap-southeast-1',
        },
        {
          name: 'leadAPI',
          endpoint: `https://api.lead${generateStage(releaseStage)}.mhub.my`,
          region: 'ap-southeast-1',
        },
        {
          name: 'projectAPI',
          endpoint: `https://projects-api.${releaseStage}.mhub.my`,
          region,
        },
      ],
    },
  };
};
