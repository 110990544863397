import qs from 'query-string';

export const getQuery = (location = window.location): any => qs.parse(location.search);

export function encodeRedirect(url = '/') {
  let nextBase64 = '';
  if (url) {
    try {
      nextBase64 = btoa(url);
    } catch (e) {
      // window.bugsnagClient.notify(e);
    }
  }
  return nextBase64;
}

export function decodeRedirect(nextBase64: string) {
  let url = '';
  if (nextBase64) {
    try {
      url = atob(nextBase64);
    } catch (e) {
      // window.bugsnagClient.notify(e);
    }
  }
  return url;
}

export function parseRedirect(nextBase64: string) {
  return `?redirect=${nextBase64}`;
}

export function parseParams(url: string, query = {}, hash = {}) {
  let parsedURL = url;
  if (query && Object.keys(query).length > 0) {
    parsedURL += `?${qs.stringify(query)}`;
  }
  if (hash && Object.keys(hash).length > 0) {
    parsedURL += `#${qs.stringify(hash)}`;
  }
  return parsedURL;
}

/**
 * Generate stage according to MHub Service API convention.
 * - Dev: https://service-api.dev.mhub.my
 * - Staging: https://service-api.stage.mhub.my
 * - Demo: https://service-api.demo.mhub.my
 * - Prod: https://service-api.mhub.my
 *
 * Example:
 * - `generateStage('dev')` will return `".dev"
 * - `generateStage('prod')` will return `""`
 * - `generateStage('prod', { include: true })` will return `".prod"`
 */
export function generateStage(
  stage: string,
  { include } : { include: boolean } = { include: false },
): string {
  if (stage === 'prod' && !include) {
    return '';
  }
  return `.${stage}`;
}
