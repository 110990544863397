import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import {
  createContext, ReactNode, useContext, useState,
} from 'react';

import { isStringArray } from '../utils';
import { logError } from '../utils/logs';
import { encodeRedirect, parseRedirect } from '../utils/url';

const ADMIN_USER_GROUPS = ['SUPER_ADMIN', 'ADMIN_BASIC', 'ADMIN_COMMUNITY'];

const { REACT_APP_LOGIN_URL } = process.env;
interface AuthContext {
  /**
   * Authencation state
   */
  authenticated: boolean;

  /**
   * Informs if session is authenticating
   */
  loading: boolean;

  /**
   * AWS username
   */
  username: string;

  /**
   * User's name
   */
  name: string;

  /**
   * User groups
   */
  userGroup: string;

  /**
   * Authenticate user's Cognito session.
   * Returns the status of authentication. Auto redirects to
   * login screen when not authenticated - `redirect` defaults to `true`.
   */
  authenticate(redirect?: boolean): Promise<boolean>;

  /**
   * Sign out of cognito session.
   * Set `global` param to `true` to logout of all devices.
   * Returns the status of signing out.
   */
  signOut(): Promise<boolean>;
}

const authContext = createContext<AuthContext>({
  authenticated: false,
  loading: false,
  username: '',
  name: '',
  userGroup: '',
  authenticate: () => Promise.resolve(false),
  signOut: () => Promise.resolve(false),
});

export function useProvideAuth(): AuthContext {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [userGroup, setUserGroup] = useState('');

  const redirectLogin = () => {
    if (REACT_APP_LOGIN_URL) {
      const urlRedirect = parseRedirect(encodeRedirect(window.location.href));
      window.location.href = `${REACT_APP_LOGIN_URL}/login${urlRedirect}`;
    }
  };

  const signOut = async (): Promise<boolean> => {
    setLoading(false);
    setAuthenticated(false);
    setUsername('');
    setName('');
    setUserGroup('');
    if (REACT_APP_LOGIN_URL) {
      window.location.href = `${REACT_APP_LOGIN_URL}/logout`;
    }
    return true;
  };

  const authenticate = async (redirect = true): Promise<boolean> => {
    setLoading(true);

    let session: CognitoUserSession | null = null;
    try {
      const authUser: CognitoUser = await Auth.currentAuthenticatedUser();
      session = authUser.getSignInUserSession();
    } catch (err) {
      if (err === 'The user is not authenticated') {
        if (redirect) {
          redirectLogin();
        }
      } else {
        logError(err);
      }
      setLoading(false);
      return false;
    }
    if (session && session.isValid()) {
      const payload = session.getIdToken().decodePayload();
      const { sub: un, name: n, 'cognito:groups': groups } = payload;

      if (isStringArray(groups)) {
        const ug = groups.find((e) => [...ADMIN_USER_GROUPS].includes(e));
        if (ug) {
          setUserGroup(ug);
        } else {
          setAuthenticated(false);
          setLoading(false);
          // eslint-disable-next-line no-alert
          alert('Invalid user access.');
          signOut();

          return false;
        }
      }
      if (n) {
        setName(n);
      }
      if (un) {
        setUsername(un);
      }
      setAuthenticated(true);
      setLoading(false);
      return true;
    }
    setLoading(false);
    setAuthenticated(false);

    return false;
  };

  return {
    authenticated,
    loading,
    username,
    name,
    userGroup,
    authenticate,
    signOut,
  };
}

export const useAuth = () => useContext(authContext);

export function ProvideAuth({
  children,
}: {
  children: ReactNode,
}) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}
