export const $colors = {
  mHubWhite: '#FFFFFF',
  mHubTeal: '#49B3B1',
  mHubTealHover: '#32C8C5',
  mHubBlue: '#0094B3',
  mhubBgBlue: '#EDF7FD',
  mHubSpace: '#00233B',
  mHubSlate: '#596975',
  mHubGray1: '#969CA4',
  mHubGray2: '#DBDDDE',
  mHubGray2b: '#E7EAED',
  mHubGray3: '#F4F5F7',
  mHubGray4: '#F8F9F9',
  mHubGray5: '#99A7B0',
  mHubMint: '#50B792',
  mHubRed: '#EE0000',
  mHubRedBg: '#FFEDED',
  mHubMustard: '#F2B42A',
  mHubMustardBg: '#FFF6D1',
  mHubPeach: '#FF796E',
  mHubOrange: '#F79A3E',
  mHubLightGreen: '#ECF7EE',
  mHubDarkGreen: '#50B793',
};

export const $fontWeight = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

export const $fontFamily = {
  openSans: '"Open Sans", serif',
  montserrat: '"Montserrat", serif',
};

export const $fontSize = {
  '10px': '0.84375rem',
  '12px': '0.984375rem',
  '14px': '1.125rem',
  '15px': '1.265625rem',
  '16px': '1.313rem',
  '17px': '1.40625rem',
  '18px': '1.5rem',
  '19px': '1.546875rem',
  '20px': '1.6rem',
  '21px': '1.6875rem',
  '22px': '1.828125rem',
  '24px': '1.96875rem',
  '32px': '2.55rem',
};

export const DESK_TOOLBAR_HEIGHT = '66px';
export const NAV_HEIGHT = 50;
export const DESK_DRAWER_WIDTH = 300;
export const DESK_DRAWER_ICON_WIDTH = 60;
export const DESK_DRAWER_SUBPANEL_WIDTH = 240;
export const DESK_CLOSE_WIDTH = 24;
export const DESK_DRAWER_ICON_AND_CLOSE_WIDTH = DESK_DRAWER_SUBPANEL_WIDTH - DESK_CLOSE_WIDTH;
