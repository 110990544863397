const reportsExportAPI = `https://reports-export-service${process.env.REACT_APP_RELEASE_STAGE === 'prod' ? '' : `.${process.env.REACT_APP_RELEASE_STAGE}`}.mhub.my`;

const lawfirmsAPI = `https://lawfirms-api.${process.env.REACT_APP_RELEASE_STAGE}.mhub.my`;

const routes = {
  home: '/',
  developers: '/developers',
  developersAffiliates: '/developers/affiliates',
  developersAffiliatesAdd: '/developers/affiliates/add',
  developersAffiliatesView: (affiliateID: string) => `/developers/affiliates/${affiliateID}`,
  developersUsers: '/developers/users',
  developersUsersAdd: '/developers/users/add',
  developersUsersView: (userID: string) => `/developers/users/${userID}`,
  lawyers: '/lawyers',
  lawyersLawyers: '/lawyers/lawyers',
  lawyersLawyersAdd: '/lawyers/lawyers/add',
  lawyersLawyersView: (lawyerID: string) => `/lawyers/lawyers/${lawyerID}`,
  lawyersLawfirms: '/lawyers/lawfirms',
  lawyersLawfirmsAdd: '/lawyers/lawfirms/add',
  lawyersLawfirmsView: (lawFirmID: string) => `/lawyers/lawfirms/${lawFirmID}`,
  reportsExportAPI,
  reportsExportAPISecureDocuments: (reportType: 'upload' | 'share') => `${reportsExportAPI}/securedocuments?reportType=${reportType}`,
  reportsExportAPIUsers: `${reportsExportAPI}/users`,
  reportsExportAPILawyers: `${reportsExportAPI}/lawyers`,
  reportsExportAPIMarketplaceRegistrations: `${reportsExportAPI}/marketplaceregistrations`,
  lawfirmsAPI,
  creditCheck: '/credit_check',
  creditCheckDashboard: '/credit_check/dashboard',
};

export default routes;
