/* eslint-disable react/jsx-props-no-spreading */
import { ComponentType, useEffect } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { getQuery, decodeRedirect } from '../../utils/url';

export interface RouteProps {
  component: ComponentType<any>;
  exact?: boolean;
  path: string | string[];
}

const Authenticate = ({
  component: C,
  props,
}: {
  component: ComponentType<any>,
  props: RouteComponentProps,
}) => {
  const {
    authenticated,
    loading,
    authenticate,
  } = useAuth();

  useEffect(() => {
    if (!authenticated && !loading) {
      authenticate();
    }
  }, [authenticated]);

  const { redirect } = getQuery();
  if (loading) return null;
  if (authenticated) {
    if (redirect) {
      let url = decodeRedirect(redirect);
      if (url.startsWith(window.location.origin)) {
        url = url.replace(window.location.origin, '');
      }
      return <Redirect from="/" to={url} />;
    }
    return <C {...props} />;
  }
  return null;
};

const RouteAuthenticated = ({
  component: C,
  ...rest
}: RouteProps) => (
  <Route
    {...rest}
    render={(props) => <Authenticate component={C} props={props} />}
  />
);

export default RouteAuthenticated;
