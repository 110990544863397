export const isStringArray = (test: any): test is string[] => (
  Array.isArray(test) && !test.some((value) => typeof value !== 'string')
);

export const isArray = (test: any): test is any[] => (
  Array.isArray(test)
);

export const isData = (test: any): test is {
  [name: string]: any;
} => (
  typeof test === 'object'
);

export const isArrayData = (test: any): test is {
  [name: string]: any
}[] => {
  if (isArray(test)) {
    return test.every((t) => isData(t));
  }
  return false;
};

export const isError = (test: any): test is Error => test && test.stack && test.message;

export const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
