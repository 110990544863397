import {
  createContext,
  ReactNode,
  useContext,
  useState,
} from 'react';
import { Color } from '@material-ui/lab/Alert';

interface AlertContext {
  /**
   * Severity state. Will set alert colour.
   */
  severity: Color;

  /**
   * Message state. Will show as message in alert.
   */
  message: string;

  /**
   * Indicator to indicate if alert is shown or hidden
   */
  alert: boolean;

  /**
   * Helper method to set alert message & severity via `AlertMessage`.
   * If `show=true`, alert will auto prompt.
   */
  setAlert: (message: AlertMessage, show?: boolean) => void;

  /**
   * Helper method to set `alert` to `true`.
   */
  showAlert: () => void;

  /**
   * Helper method to set `alert` to `false`.
   */
  hideAlert: () => void;
}

export interface AlertMessage {
  severity: Color;
  message: string;
}

const alertContext = createContext<AlertContext>({
  severity: 'info',
  message: '',
  alert: false,
  setAlert: () => { },
  showAlert: () => { },
  hideAlert: () => { },
});

export function useProvideAlert(): AlertContext {
  const [severity, setSeverity] = useState<Color>('info');
  const [message, setMessage] = useState('');
  const [alert, setShowAlert] = useState(false);

  const showAlert = () => {
    setShowAlert(true);
  };

  const setAlert = (m: AlertMessage, show?: boolean) => {
    setSeverity(m.severity);
    setMessage(m.message);
    if (show) {
      showAlert();
    }
  };

  const hideAlert = () => {
    setShowAlert(false);
  };

  return {
    severity,
    message,
    alert,
    setAlert,
    showAlert,
    hideAlert,
  };
}

export const useAlert = () => useContext(alertContext);

export function ProvideAlert({
  children,
}: {
  children: ReactNode,
}) {
  const alert = useProvideAlert();
  return (
    <alertContext.Provider value={alert}>
      {children}
    </alertContext.Provider>
  );
}
