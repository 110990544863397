import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';

import AppSnackBar from '../AppSnackbar';
import RouteAuthenticated from './RouteAuthenticated';

import routes from './routes';

export { routes };

const Home = lazy(() => import('../Home'));
const Developers = lazy(() => import('../../apps/developers/Developers'));
const Lawyers = lazy(() => import('../../apps/lawyers/Lawyers'));
const Creditcheck = lazy(() => import('../../apps/creditcheck/Creditcheck'));

export default function Routes() {
  return (
    <Router>
      <AppSnackBar />
      <Suspense fallback={<></>}>
        <Switch>
          <RouteAuthenticated path={routes.creditCheck} component={Creditcheck} />
          <RouteAuthenticated path={routes.developers} component={Developers} />
          <RouteAuthenticated path={routes.lawyers} component={Lawyers} />
          <RouteAuthenticated path={routes.home} component={Home} />
          <Redirect to={routes.home} />
        </Switch>
      </Suspense>
    </Router>
  );
}
