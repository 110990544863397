export const generateStage = (
  stage: string,
  {
    includeProd,
    hasDev,
  } : {
    includeProd?: boolean,
    hasDev?: boolean,
  } = {
    includeProd: false,
    hasDev: true,
  },
): string => {
  switch (stage) {
    case 'prod':
      if (!includeProd) {
        return '';
      }
      break;
    case 'dev':
      if (!hasDev) {
        return 'stage';
      }
      break;
    default:
      break;
  }
  return `${stage}`;
};

export const generateCompanyAPI = (stage: string) => {
  switch (stage) {
    case 'dev':
    case 'stage':
      return 'https://jxvds98ty1.execute-api.ap-southeast-1.amazonaws.com/stage';
    case 'demo':
      return 'https://7q8bi2u4ij.execute-api.ap-southeast-1.amazonaws.com/demo';
    case 'prod':
      return 'https://rcm3e2aqw1.execute-api.ap-southeast-1.amazonaws.com/prod';
    default:
      break;
  }
  return '';
};

export const getAWSURL = ({
  s3Bucket,
  fileURL,
  hasDev,
  includeProd,
  privatePath,
}: {
  s3Bucket: string,
  fileURL: string,
  hasDev?: boolean,
  includeProd?: boolean,
  privatePath?: boolean,
}) => {
  const releaseStage = process.env.REACT_APP_RELEASE_STAGE;
  const S3URL = `https://${s3Bucket}-${generateStage(releaseStage, { includeProd, hasDev })}.s3.ap-southeast-1.amazonaws.com`;

  return `${S3URL}${privatePath ? '/private/' : '/'}${fileURL}`;
};
