import {
  Snackbar,
} from '@material-ui/core';
import AppAlert from './AppAlert';
import { useAlert } from '../hooks/useAlert';

export default function AppSnackBar() {
  const {
    severity,
    message,
    alert,
    hideAlert,
  } = useAlert();
  return (
    <Snackbar
      open={alert}
      autoHideDuration={6000}
      onClose={hideAlert}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <AppAlert onClose={hideAlert} severity={severity}>
        {message}
      </AppAlert>
    </Snackbar>
  );
}
